import "./about.css"
import Award from "../../img/award.png"
import harward from "../../img/Harvard.png"
import udemy1 from "../../img/udemy1.png"
import udemy2 from "../../img/udemy2.png"

const About = () => {
  return (
    <div className="a">
        <div className="a-left">
            <div className="a-card bg"></div>
            <div className="a-card">
                <img 
                 src= {harward}
                 alt="" 
                 className="a-img" />
            </div>
        </div>
        <div className="a-right">
        <h1 className="a-title">About Me</h1>
        <p className="a-sub">
          More than a programer I am a problem solver 
        </p>
        <p className="a-desc">
            I am from germany and was born in 2003. Currently I go to school and work at the same time.
            Even tough I'm not yet a CS studnet I took some courses in order to develop my abilities.
            As result I achieved some certificates from Harvard university and Udemy. 
            
            </p>
            <div className="a-award">
                <div className="a-award-imgs">
                    <img src={udemy1} alt="" className="a-award-img" />
                    <img src={udemy2} alt="" className="a-award-img" />
                </div>
            <div className="a-award-texts">
                <h4 className="a-award-title">CS 50 Harvard 2021</h4>
                <p className="a-award-desc">
                    Full-stack development with MERN made by Dr.Angela Yu
                </p>
                <div className="a-title">
                    <div className="a-title-wrapper">
                        <div className="a-title-item">React</div>
                        <div className="a-title-item">Node JS</div>
                        <div className="a-title-item">MangoDB</div>
                        <div className="a-title-item">Javascript</div>
                        <div className="a-title-item">Express</div>
                        <div className="a-title-item">HTML/CSS</div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
  )
}

export default About