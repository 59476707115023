import Me2 from "./img/Me2.png"
import landing from "./img/landing.png"
import restauran from "./img/restauran.png"
import blog from "./img/blog.png"
import ecommerce from "./img/React App.png"
import note from "./img/note.png"
import blog2 from "./img/blog2.png"
export const products = [
    {
      id: 1,
      img: note,
      
    },
    {
      id: 2,
      img: landing,
      
    },
    {
      id: 3,
      img: restauran,
      
    },
    {
      id: 4,
      img: blog,
     
    },
    {
      id: 5,
      img: ecommerce,
      
    },
    {
      id: 6,
      img: blog2,
      
    },
  ];